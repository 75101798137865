export const exportExcel = (tableHeader, dataList,name) => {
  require.ensure([], () => {
    const { export_json_to_excel } = require("./Export2Excel");
    let tHeader = []; // 设置Excel的表格第一行的标题
    let filterVal = []; // 对象tableData中一个对象的属性
    tableHeader.forEach((v) => {
      tHeader.push(v.dataName);
      filterVal.push(v.dataItem);
    });
    // console.log(tHeader, filterVal);
    const list = dataList; //把data里的tableData存到list
    // console.log(list);
    const data = formatJson(filterVal, list); //对数据过滤
    const head = name;
    // console.log(tHeader, data, head);
    // tHeader：第一行标题； data:要显示的数据；head：下载的文件名
    export_json_to_excel(tHeader, data, head);
  });
};

function formatJson(filterVal, jsonData) {
  return jsonData.map((v) => filterVal.map((j) => v[j]));
}

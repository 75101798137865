<template>
  <el-dialog
    :title="type === 1 ? '新增费率信息' : '修改费率信息'"
    :visible.sync="visible"
    width="60%"
    @close="close"
  >
    <el-form :model="rateForm" ref="rateForm" :rules="rules">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="费率名称" prop="name">
            <el-input
              v-model="rateForm.name"
              :disabled="viewDisable"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="谷期电价" prop="lowTimePrice">
            <el-input
              v-model="rateForm.lowTimePrice"
              :disabled="viewDisable"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="平期电价" prop="normalTimePrice">
            <el-input
              v-model="rateForm.normalTimePrice"
              :disabled="viewDisable"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="高峰电价" prop="highTimePrice">
            <el-input
              v-model="rateForm.highTimePrice"
              :disabled="viewDisable"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="尖峰电价" prop="sharpTimePrice">
            <el-input
              v-model="rateForm.sharpTimePrice"
              :disabled="viewDisable"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="高尖峰电价" prop="highSharpTimePrice">
            <el-input
              v-model="rateForm.highSharpTimePrice"
              :disabled="viewDisable"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="sharpMonth" label="年尖峰月份">
            <el-select
              placeholder="请选择年尖峰月份"
              multiple
              v-model="rateForm.sharpTimeMonth"
              :disabled="viewDisable"
            >
              <!-- <el-option label="选择所有" value="all"></el-option> -->
              <el-option
                v-for="item in month"
                :label="item.label"
                :value="item.value"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div
        v-for="(item, j) in rateForm.rateTime"
        :key="j"
        class="flex items-middle"
      >
        <template v-if="j === 0">
          <el-form-item
            style="flex: 5"
            label="起始时间"
            :prop="'rateTime.' + j + '.startTime'"
            :rules="rules.startTime"
          >
            <el-time-select
              placeholder="开始时间"
              v-model="item.startTime"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '24:00',
              }"
              :disabled="viewDisable"
            >
            </el-time-select>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item
            label="起始时间"
            style="flex: 5"
            :prop="'rateTime.' + j + '.startTime'"
            :rules="rules.startTime"
          >
            <el-time-select
              placeholder="开始时间"
              v-model="item.startTime"
              :disabled="viewDisable"
              :picker-options="{
                start: rateForm.rateTime[j - 1].endTime,
                step: '00:30',
                end: '24:00',
              }"
            >
            </el-time-select>
          </el-form-item>
        </template>
        <el-form-item
          label="结束时间"
          style="flex: 5"
          :prop="'rateTime.' + j + '.endTime'"
          :rules="rules.endTime"
        >
          <el-time-select
            placeholder="结束时间"
            v-model="item.endTime"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '24:00',
              minTime: item.startTime,
            }"
            :disabled="viewDisable"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item
          label="时段"
          style="flex: 5"
          :prop="'rateTime.' + j + '.timeSection'"
          :rules="rules.timeSection"
        >
          <el-select
            v-model="item.timeSection"
            placeholder="请选择时段"
            :disabled="viewDisable"
          >
            <el-option label="尖峰" value="1"></el-option>
            <el-option label="高峰" value="2"></el-option>
            <el-option label="平期" value="3"></el-option>
            <el-option label="谷期" value="4"></el-option>
            <el-option label="高尖峰" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="充放功率"
          style="flex: 5"
          :prop="'rateTime.' + j + '.status'"
          :rules="rules.status"
        >
          <el-input
            type="number"
            v-model="item.status"
            placeholder="请输入充放功率"
          ></el-input>
        </el-form-item>
        <div style="flex: 1; padding-bottom: 28px">
          <i
            v-if="j === 0"
            @click="
              rateForm.rateTime[rateForm.rateTime.length - 1].endTime == '24:00'
                ? notAdd(
                    rateForm.rateTime[rateForm.rateTime.length - 1].endTime
                  )
                : addDateList()
            "
            style="font-size: 20px; color: #25c4fe; margin: 8px 0 0 10px"
            class="el-icon-circle-plus"
          ></i>
          <i
            v-if="j > 0"
            @click="deleteDateList(j)"
            style="font-size: 20px; color: #25c4fe; margin: 8px 0 0 10px"
            class="el-icon-remove"
          ></i>
        </div>
      </div>
      <el-form-item label="费率说明">
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="rateForm.note"
          maxlength="200"
          show-word-limit
          :disabled="viewDisable"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-if="type != 3">
      <el-button
        class="btn-blue"
        type="primary"
        style="color: #000"
        @click="onSubmit('rateForm')"
        >{{
          type === 1 ? $t("addDevice.add") : $t("addDevice.edit")
        }}</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { rateAdd, rateEdit } from "../api/rateList";
import { deepClone } from "../utils/deepClone";
import i18n from "@/lang";
export default {
  data() {
    var price = (rule, value, callback) => {
      const reg = /^\d+.?\d{0,5}$/;
      if (!value) {
        callback(new Error("请输入价格"));
      } else if (!Number(value)) {
        callback(new Error("请输入数字值"));
      } else {
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("小数点后最多只能输入五位"));
        }
      }
    };

    return {
      viewDisable: false,
      visible: false,
      type: 1, //1新增 2编辑
      rateForm: {
        lowTimePrice: 0,
        normalTimePrice: 0,
        sharpTimePrice: 0,
        highSharpTimePrice: 0,
        highTimePrice: 0,
        note: "",
        id: "",
        name: "",
        sharpTimeMonth: [],
        rateTime: [
          {
            startTime: "",
            endTime: "",
            timeSection: "",
            status: "",
          },
        ],
      },
      rules: {
        id: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        name: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        lowTimePrice: [
          {
            required: true,
            validator: price,
            trigger: "blur",
          },
        ],
        normalTimePrice: [
          {
            required: true,
            validator: price,
            trigger: ["blur"],
          },
        ],
        highTimePrice: [
          {
            required: true,
            validator: price,
            trigger: ["blur"],
          },
        ],
        sharpTimePrice: [
          {
            required: true,
            validator: price,
            trigger: ["blur"],
          },
        ],
        highSharpTimePrice: [
          {
            required: true,
            validator: price,
            trigger: ["blur"],
          },
        ],
        startTime: [
          {
            required: true,
            message: "不为空",
            trigger: "change",
          },
        ],
        endTime: [
          {
            required: true,
            message: "不为空",
            trigger: "change",
          },
        ],
        timeSection: [
          {
            required: true,
            message: "不为空",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "不为空",
            trigger: "change",
          },
        ],
      },
      month: [
        {
          value: 1,
          label: "1月",
        },
        {
          value: 2,
          label: "2月",
        },
        {
          value: 3,
          label: "3月",
        },
        {
          value: 4,
          label: "4月",
        },
        {
          value: 5,
          label: "5月",
        },
        {
          value: 6,
          label: "6月",
        },
        {
          value: 7,
          label: "7月",
        },
        {
          value: 8,
          label: "8月",
        },
        {
          value: 9,
          label: "9月",
        },
        {
          value: 10,
          label: "10月",
        },
        {
          value: 11,
          label: "11月",
        },
        {
          value: 12,
          label: "12月",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    // 新增
    addDateList() {
      let info = {
        startTime: "",
        endTime: "",
        status: "",
        timeSection: "",
      };
      this.rateForm.rateTime.push(info);
    },
    // 删除
    deleteDateList(index) {
      this.rateForm.rateTime.splice(index, 1);
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.rateForm);
          let obj = JSON.parse(JSON.stringify(this.rateForm));
          obj.sharpTimeMonth = obj.sharpTimeMonth.join(",");
          obj.lowTimePrice = parseFloat(obj.lowTimePrice);
          obj.normalTimePrice = parseFloat(obj.normalTimePrice);
          obj.sharpTimePrice = parseFloat(obj.sharpTimePrice);
          obj.highSharpTimePrice = parseFloat(obj.highSharpTimePrice);
          obj.highTimePrice = parseFloat(obj.highTimePrice);
          obj.rateTime = JSON.stringify(obj.rateTime);
          console.log(obj);
          if (this.type === 1) {
            this.addInfo(obj);
          } else if (this.type === 2) {
            this.editInfo(obj);
          }
        }
      });
    },
    addInfo(obj) {
      console.log("add", obj);
      rateAdd(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: i18n.t("prompt.operateSuccessfully"),
          });
          this.$emit("refresh");
          this.visible = false;
        } else {
          this.$message({
            type: "error",
            message: i18n.t("prompt.operationFailure") + res.data.message,
          });
        }
      });
    },
    editInfo(obj) {
      console.log("edit", obj);
      rateEdit(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: i18n.t("prompt.operateSuccessfully"),
          });
          this.$emit("refresh");
          this.visible = false;
        } else {
          this.$message({
            type: "error",
            message: i18n.t("prompt.operationFailure") + res.data.message,
          });
        }
      });
    },
    open(type, row) {
      console.log(typeof type, type);
      this.visible = true;
      this.type = type;
      if (type === 1) {
        this.viewDisable = false;
        this.rateForm = {
          sharpTimeMonth: [],
          rateTime: [
            {
              startTime: "",
              endTime: "",
              timeSection: "",
              status: "",
            },
          ],
        };
      } else if (this.type === 2) {
        let obj = deepClone(row);
        this.viewDisable = false;
        // this.rateForm = JSON.parse(JSON.stringify(row));
        // console.log(row, obj, deepClone(obj), "hhhhhh");
        obj.rateTime = JSON.parse(obj.rateTime);
        obj.sharpTimeMonth = obj.sharpTimeMonth.split(",");
        this.rateForm = deepClone(obj);
      } else if (type === 3) {
        this.viewDisable = true;
        let obj = deepClone(row);
        // this.rateForm = JSON.parse(JSON.stringify(row));
        obj.rateTime = JSON.parse(obj.rateTime);
        obj.sharpTimeMonth = obj.sharpTimeMonth.split(",");
        this.rateForm = deepClone(obj);
      }
      console.log(this.viewDisable);
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
<style lang="stylus" scoped>
/deep/ .el-input{
  width 60% !important
}
/deep/ .el-select{
  width 60% !important
}
/deep/ .el-select .el-input{
  width 100% !important
}
/deep/ .el-dialog, .el-message-box{
  min-width 1040px !important
}
</style>

import { http } from "../utils/http";

export const rateQuery = (data) =>
  http.post("/weilan/api/rate/info/query", data);
export const rateDel = (data) =>
  http.post("/weilan/api/rate/info/delete", data);
export const rateAdd = (data) => http.post("/weilan/api/rate/info/add", data);

export const rateEdit = (data) =>
  http.post("/weilan/api/rate/info/modify", data);

import { http } from "../utils/http";

export const queryListByDay = (data) =>
  http.post("/weilan/api/benefit/history/info/day/query", data);
export const queryListByMonth = (data) =>
  http.post("/weilan/api/benefit/history/info/month/query", data);

export const monthAdd = (data) =>
  http.post("/weilan/api/benefit/history/info/month/add", data);
export const monthModify = (data) =>
  http.post("/weilan/api/benefit/history/info/month/modify", data);

export const billQuery = (data) =>
  http.post("/weilan/api/user/bill/query", data);

export const downloadPdf = (data) =>
  http.post("/weilan/api/user/bill/downloadPdf", data);
